export const US_STATE_OPTIONS = [
  {
    value: "AK",
    label: "Alaska",
  },
  {
    value: "AL",
    label: "Alabama",
  },
  {
    value: "AR",
    label: "Arkansas",
  },
  {
    value: "AZ",
    label: "Arizona",
  },
  {
    value: "CA",
    label: "California",
  },
  {
    value: "CO",
    label: "Colorado",
  },
  {
    value: "CT",
    label: "Connecticut",
  },
  {
    value: "DC",
    label: "District of Columbia",
  },
  {
    value: "DE",
    label: "Delaware",
  },
  {
    value: "FL",
    label: "Florida",
  },
  {
    value: "GA",
    label: "Georgia",
  },
  {
    value: "GU",
    label: "Guam",
  },
  {
    value: "HI",
    label: "Hawaii",
  },
  {
    value: "IA",
    label: "Iowa",
  },
  {
    value: "ID",
    label: "Idaho",
  },
  {
    value: "IL",
    label: "Illinois",
  },
  {
    value: "IN",
    label: "Indiana",
  },
  {
    value: "KS",
    label: "Kansas",
  },
  {
    value: "KY",
    label: "Kentucky",
  },
  {
    value: "LA",
    label: "Louisiana",
  },
  {
    value: "MA",
    label: "Massachusetts",
  },
  {
    value: "MD",
    label: "Maryland",
  },
  {
    value: "ME",
    label: "Maine",
  },
  {
    value: "MI",
    label: "Michigan",
  },
  {
    value: "MN",
    label: "Minnesota",
  },
  {
    value: "MO",
    label: "Missouri",
  },
  {
    value: "MS",
    label: "Mississippi",
  },
  {
    value: "MT",
    label: "Montana",
  },
  {
    value: "NC",
    label: "North Carolina",
  },
  {
    value: "ND",
    label: "North Dakota",
  },
  {
    value: "NE",
    label: "Nebraska",
  },
  {
    value: "NH",
    label: "New Hampshire",
  },
  {
    value: "NJ",
    label: "New Jersey",
  },
  {
    value: "NM",
    label: "New Mexico",
  },
  {
    value: "NV",
    label: "Nevada",
  },
  {
    value: "NY",
    label: "New York",
  },
  {
    value: "OH",
    label: "Ohio",
  },
  {
    value: "OK",
    label: "Oklahoma",
  },
  {
    value: "OR",
    label: "Oregon",
  },
  {
    value: "PA",
    label: "Pennsylvania",
  },
  {
    value: "PR",
    label: "Puerto Rico",
  },
  {
    value: "RI",
    label: "Rhode Island",
  },
  {
    value: "SC",
    label: "South Carolina",
  },
  {
    value: "SD",
    label: "South Dakota",
  },
  {
    value: "TN",
    label: "Tennessee",
  },
  {
    value: "TX",
    label: "Texas",
  },
  {
    value: "UT",
    label: "Utah",
  },
  {
    value: "VA",
    label: "Virginia",
  },
  {
    value: "VT",
    label: "Vermont",
  },
  {
    value: "WA",
    label: "Washington",
  },
  {
    value: "WI",
    label: "Wisconsin",
  },
  {
    value: "WV",
    label: "West Virginia",
  },
  {
    value: "WY",
    label: "Wyoming",
  },
];

export const JOB_POSITION_OPTIONS = [
  {
    value: "fulltime",
    label: "Full-time",
  },
  {
    value: "internship",
    label: "Internship",
  },
];

export const WEBINAR_DURATION_OPTIONS = [
  {
    value: 30,
    label: "30 Minutes",
  },
  {
    value: 45,
    label: "45 Minutes",
  },
  {
    value: 60,
    label: "60 Minutes",
  },
  {
    value: 90,
    label: "90 Minutes",
  },
];

export const APPOINTMENT_RATE = {
  FIXED: "fixed_rate",
  HOURLY: "hourly_rate",
};

export const APPOINTMENT_TYPE = {
  IN_PERSON: "in_person",
  ZOOM: "zoom",
};

export const SET_CALENDER_OPTIONS = {
  CALENDLY: "calendly",
  GOOGLE: "google",
  OUTLOOK: "outlook",
};

export const AVAILABLE_PLATFORMS = {
  OUTLOOK: "outlook_calendar",
  GOOGLE: "google_calendar",
  ZOOM: "zoom",
};

export const menuNames = {
  USERS: "USERS",
  POST: "POST",
  TOPICS: "COMMUNITY",
  ENGAGE: "ENGAGE",
  MANAGE: "MANAGE",
  MEET: "MEET",
  CHAT: "CHAT",
  NETWORK: "NETWORK",
  GROUPS: "GROUPS",
  STATS: "STATS",
};

export const userTypes = {
  GENERAL_ADVISOR: "general_advisor",
};

export const OutOfOffice = "Out of office";

export const NOTIFICATION_FILTERS = [
  {
    title: "Networking",
    value: "networking",
  },
  {
    title: "Community",
    value: "topics",
  },
  {
    title: "Webinar",
    value: "webinars",
  },
  {
    title: "Resources",
    value: "other_resources",
  },
  {
    title: "Services",
    value: "networking",
  },
  {
    title: "Calendar",
    value: "calendar",
  },
];
export const defaultTrue = {
  isAvailable: true,
  startTime: { label: "09:00 AM", value: 540 },
  endTime: { label: "05:00 PM", value: 1020 },
};

export const defaultFalse = {
  isAvailable: false,
  startTime: null,
  endTime: null,
};

export const defaultAvailability = [
  {
    day_of_week: 1,
    day: "SUN",
    ...defaultFalse,
  },
  {
    day_of_week: 2,
    day: "MON",
    ...defaultTrue,
  },
  {
    day_of_week: 3,
    day: "TUE",
    ...defaultTrue,
  },
  {
    day_of_week: 4,
    day: "WED",
    ...defaultTrue,
  },
  {
    day_of_week: 5,
    day: "THU",
    ...defaultTrue,
  },
  {
    day_of_week: 6,
    day: "FRI",
    ...defaultTrue,
  },
  {
    day_of_week: 7,
    day: "SAT",
    ...defaultFalse,
  },
];

export const BASE_PATH = "/";

export const ROUTES = {
  BASE: `${BASE_PATH}`,
  FORGOT_PASSWORD: `${BASE_PATH}forgot-password`,
  RESET_PASSWORD: `${BASE_PATH}reset-password`,
  NOTIFICATION_UNSUBSCRIBE: `${BASE_PATH}notification/unsubscribe`,
  CAREER_DASHBOARD: `${BASE_PATH}dashboard`,
  ADMISSIONS_DASHBOARD: `${BASE_PATH}admissions/dashboard`,
};

export const AccountTypes = [
  { value: "0", label: "Student" },
  { value: "1", label: "Alumni" },
  { value: "2", label: "Staff" },
  { value: "5", label: "Admin" },
];

export const ACCOUNT_TYPE_ENABLED = [
  "Resource",
  "Webinar",
  "HomeFeed",
  "Service",
];

export const INTERSTRIDE_DEFAULT_LOGO =
  "https://interstride.s3.amazonaws.com/branding/brand-header-default-logo.svg";

export const INTERSTRIDE_EMBLEM =
  "https://interstride.s3.amazonaws.com/branding/square-emblem-logo.svg";

export const INTERSTRIDE_LOGO_WHITE =
  "https://interstride.s3.amazonaws.com/branding/brand-header-default-logo-inverse.svg";
